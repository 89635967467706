<template>
  <div class="JZContainer">
    <QueryForm
      label-position="right"
      ref="JZForm"
      :model="JZForm"
      label-width="112px"
      class="content_block"
      @search="handleSearch"
      @reset="handleReset"
    >
      <el-form-item label="员工姓名：" prop="name">
        <el-input placeholder="请输入员工姓名" v-model.trim="JZForm.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="直属部门：" prop="departmentName">
        <el-input placeholder="请输入直属部门" v-model.trim="JZForm.departmentName" clearable></el-input>
      </el-form-item>
      <el-form-item label="员工手机号：" prop="phoneNumber">
        <el-input placeholder="请输入员工手机号" v-model.trim="JZForm.phoneNumber" clearable></el-input>
      </el-form-item>
    </QueryForm>
    <TableWithPagination
      :tableData="targetLists"
      class="content_block"
      :columnList="columnList"
      :leftToolbar="tableButtons"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
    ></TableWithPagination>
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="720px"
      :append-to-body="$store.state.common.appendToBody"
      :destroyOnClose="$store.state.common.destroyOnClose"
      :close-on-click-modal="$store.state.common.closeOnClickModal"
    >
      <div v-if="dialogFlag === 'editAccount'" class="content" style="max-height: 500px; overflow: auto">
        <ContentBlock>
          <ul class="statusContainer">
            <li
              v-for="status in statusLists"
              :key="status.value"
              @click="currentStatus = status.value"
              :class="{ active: currentStatus === status.value }"
            >
              {{ status.label }}
            </li>
          </ul>
          <el-form
            label-position="right"
            v-if="currentStatus === 'STATUS'"
            ref="JZDialogForm"
            :rules="JZAccountStatusRules"
            :model="JZAccountStatus"
            label-width="110px"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="账号名称：" prop="phoneNumber">
                  <el-input
                    placeholder="请输入账号名称"
                    v-model.trim="JZAccountStatus.phoneNumber"
                    disabled
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账号状态：" prop="accountStatus">
                  <el-radio-group v-model="JZAccountStatus.accountStatus">
                    <el-radio label="DISABLE">禁用</el-radio>
                    <el-radio label="ACTIVE">激活</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form
            label-position="right"
            v-if="currentStatus === 'RESETPASSWORD'"
            ref="JZDialogForm"
            :rules="JZAccountPasswordRules"
            :model="JZAccountPassword"
            label-width="125px"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="新密码：" prop="newPassword">
                  <span slot="label">
                    新密码：
                    <el-tooltip class="item" effect="dark" placement="top-start">
                      <div slot="content">由6-11位字符，支持英文字母及数字符号。</div>
                      <i class="el-icon-info" />
                    </el-tooltip>
                  </span>
                  <el-input
                    placeholder="请输入新密码"
                    type="password"
                    v-model.trim="JZAccountPassword.newPassword"
                    clearable
                    show-password
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="确认密码：" prop="againPassword">
                  <span slot="label">
                    确认密码：
                    <el-tooltip class="item" effect="dark" placement="top-start">
                      <div slot="content">由6-11位字符，支持英文字母及数字符号。</div>
                      <i class="el-icon-info" />
                    </el-tooltip>
                  </span>
                  <el-input
                    placeholder="请输入确认密码"
                    type="password"
                    v-model.trim="JZAccountPassword.againPassword"
                    clearable
                    show-password
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </ContentBlock>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="isShow = false" type="default" plain size="mini">
          取 消
        </el-button>
        <el-button v-if="dialogFlag === 'editAccount'" @click="handleSaveAndUpdateEmployee" type="primary" size="mini">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import SystemSetting from '@/api/systemSetting';
import * as dayjs from 'dayjs';

export default {
  name: 'Employee',
  mixins: [InfoMixin],
  created () {
    this.handleSearch();
  },
  computed: {
    JZAccountPasswordRules () {
      return {
        newPassword: [
          {
            required: true,
            message: '新密码不可为空！',
            trigger: ['blur', 'change'],
          },
          {
            validator: this.validatorPassword,
            trigger: ['blur', 'change'],
          },
        ],
        againPassword: [
          {
            required: true,
            message: '确认密码不可为空！',
            trigger: ['blur', 'change'],
          },
          {
            validator: this.validatorAgainPassword,
            trigger: ['blur', 'change'],
          },
        ],
      };
    },
  },
  data () {
    return {
      currentEmployee: {},
      loading: false,
      title: '账号修改',
      dialogFlag: 'employeeTag',
      isShow: false,
      JZForm: {
        name: '',
        departmentName: '',
        phoneNumber: '',
      },
      JZInfo: {
        adminFlag: 2,
        name: '',
        phoneNumber: '',
        status: 'ON_JOB',
        roleNamesStr: '',
        roleRespDTOList: [],
        email: '',
        departmentName: '',
        accountStatus: 'ACTIVE',
        accountStatusName: '',
        departmentId: '',
        workStatusName: '空闲',
        id: '',
      },
      targetLists: [],
      tableButtons: [
        {
          name: '新增', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'addNew', // code(必填)
          icon: 'vxe-icon--plus', // 按钮内部icon
          permission: 'system:employee:add', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.addNew, // 点击回调事件
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '序号',
          minWidth: '20px',
          type: 'index',
        },
        {
          tooltip: true,
          label: '姓名',
          minWidth: '100px',
          prop: 'name',
          render: (h, { row }) => {
            let actions = [];
            let queryStatus
              = (this.$store.state.Auth && this.$store.state.Auth.actionPermissionMap['system:employee:view']) || false;
            if (queryStatus) {
              actions.push(
                h(
                  'span',
                  {
                    domProps: {
                      className: 'table-action',
                    },
                    on: {
                      click: this.handleDetail.bind(this, row, 'employeeDetail'),
                    },
                  },
                  row.name,
                ),
              );
              return h('div', actions);
            } else {
              return h('div', row.name);
            }
          },
        },
        {
          tooltip: true,
          label: '手机号',
          minWidth: '100px',
          prop: 'phoneNumber',
        },
        {
          tooltip: true,
          label: '员工工号',
          minWidth: '120px',
          prop: 'jobNumber',
        },
        {
          tooltip: true,
          label: '直属部门',
          minWidth: '110px',
          prop: 'departmentName',
        },
        {
          tooltip: true,
          label: '角色',
          minWidth: '110px',
          prop: 'roleNamesStr',
        },
        {
          tooltip: true,
          label: '员工状态',
          minWidth: '100px',
          prop: 'statusName',
        },
        {
          tooltip: true,
          label: '最后登录时间',
          minWidth: '110px',
          prop: 'lastLoginTime',
          format: val => {
            return val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              h(
                'span',
                {
                  domProps: {
                    className: 'table-action',
                  },
                  directives: [
                    {
                      name: 'auth',
                      value: 'system:employee:edit',
                    },
                  ],
                  on: {
                    click: this.handleDetail.bind(this, row, 'editEmployee'),
                  },
                },
                '修改员工',
              ),
            );
            actions.push(
              h(
                'span',
                {
                  domProps: {
                    className: 'table-action',
                  },
                  directives: [
                    {
                      name: 'auth',
                      value: 'system:employee:accountEdit',
                    },
                  ],
                  on: {
                    click: this.handleDetail.bind(this, row, 'editAccount'),
                  },
                },
                '账号修改',
              ),
            );
            actions.push(
              h(
                'span',
                {
                  domProps: {
                    className: 'table-action table-action-delete',
                  },
                  directives: [
                    {
                      name: 'auth',
                      value: 'system:employee:delete',
                    },
                  ],
                  on: {
                    click: this.handleDelete.bind(this, row),
                  },
                },
                '删除',
              ),
            );
            return h(
              'div',
              {
                domProps: {
                  className: 'content-overflow',
                },
              },
              actions,
            );
          },
        },
      ],
      statusLists: [
        {
          label: '状态编辑',
          value: 'STATUS',
        },
        {
          label: '密码重置',
          value: 'RESETPASSWORD',
        },
      ],
      currentStatus: 'STATUS',
      JZAccountStatus: {
        id: '',
        accountStatus: '',
        phoneNumber: '',
      },
      JZAccountStatusRules: {
        accountStatus: [
          {
            required: true,
            message: '账号状态不可为空！',
            trigger: ['blur', 'change'],
          },
        ],
      },
      JZAccountPassword: {
        id: '',
        newPassword: '',
        againPassword: '',
      },
    };
  },
  methods: {
    cancelRowEvent (row) {
      const $table = this.$refs.xTable;
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row);
      });
    },
    editRowEvent (row) {
      const $table = this.$refs.xTable;
      $table.setActiveRow(row);
    },
    handleReset () {
      this.JZForm = this.$options.data().JZForm;
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = this.getCurrentParams();
      SystemSetting.getEmployLists(currentParams).then(this.getTargetListsSuccessFun);
    },
    getCurrentParams () {
      return {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        findDepartmentFlag: true,
        findRoleFlag: true,
        pageFlag: true,
        departmentId: '',
        name: this.JZForm.name,
        departmentName: this.JZForm.departmentName,
        phoneNumber: this.JZForm.phoneNumber,
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
      };
    },
    handleDetail (info, flag) {
      this.dialogFlag = flag;

      if (flag === 'employeeDetail') {
        // 跳转员工详情
        this.$router.push({
          name: 'EmployeeDetail',
          query: {
            id: info.id,
          },
        });

        return;
      }

      if (flag === 'editEmployee') {
        // 跳转员工编辑
        this.$router.push({
          name: 'EmployeeEdit',
          query: {
            id: info.id,
          },
        });
        return;
      }

      // editAccount => 账号修改
      this.title = '账号修改';
      SystemSetting.getDetailEmploy({
        id: info.id,
        findDepartmentFlag: true,
        findRoleFlag: true,
      }).then(this.getDetailAccountSuccessFun);
    },
    getDetailAccountSuccessFun (jsonData) {
      if (jsonData.heads.code === 200) {
        let accountInfo = jsonData.body;
        this.JZInfo = {
          name: accountInfo.name,
          phoneNumber: accountInfo.phoneNumber,
          status: accountInfo.status || 'ON_JOB',
          roleNamesStr: accountInfo.roleNamesStr,
          roleRespDTOList: accountInfo.roleRespDTOList.map(item => item.id),
          email: accountInfo.email,
          departmentName: accountInfo.departmentName,
          accountStatus: accountInfo.accountStatus || 'ACTIVE',
          departmentId: accountInfo.departmentId,
          accountStatusName: accountInfo.accountStatusName,
          id: accountInfo.id,
          workStatusName: accountInfo.workStatusName,
          adminFlag: accountInfo.adminFlag ? 1 : 2,
        };

        this.currentStatus = 'STATUS';
        this.JZAccountStatus = {
          id: accountInfo.id,
          phoneNumber: accountInfo.phoneNumber,
          accountStatus: accountInfo.accountStatus || 'ACTIVE',
        };
        this.JZAccountPassword = {
          id: accountInfo.id,
          newPassword: '',
          againPassword: '',
        };

        this.$nextTick(() => {
          this.$refs.JZDialogForm.clearValidate();
        });
        this.isShow = true;
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
    },
    addNew () {
      // 跳转员工新增页面
      this.$router.push({
        name: 'EmployeeEdit',
      });
    },
    handleDelete (info) {
      this.$confirmAlert('删除该员工信息后，如有使用账号也同时被删除？', '确认要删除该员工信息？', {}, 'warning').then(
        () => {
          SystemSetting.deleteEmployee({ id: info.id }).then(this.handleReset);
        },
      );
    },
    handleSaveAndUpdateEmployee () {
      this.$refs.JZDialogForm.validate(valid => {
        if (valid) {
          if (this.currentStatus === 'STATUS') {
            SystemSetting.updateAccountStatus(this.JZAccountStatus).then(this.handleSaveOrUpdateSuccessFun);
          } else {
            SystemSetting.resetPassword(this.JZAccountPassword).then(this.handleSaveOrUpdateSuccessFun);
          }
        }
      });
    },
    handleSaveOrUpdateSuccessFun (jsonData) {
      if (jsonData.heads.code === 200) {
        this.isShow = false;
        this.handleSearch();
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
    },
    validatorPassword (rule, value, callback) {
      if (value === '') {
        callback(new Error('新密码不可为空！'));
      } else if (!/^[A-Za-z0-9]{6,11}$/.test(value)) {
        callback(new Error('新密码格式错误!'));
      } else {
        callback();
      }
    },
    validatorAgainPassword (rule, value, callback) {
      if (value === '') {
        callback(new Error('确认密码不可为空！'));
      } else if (!/^[A-Za-z0-9]{6,11}$/.test(value)) {
        callback(new Error('确认密码格式错误！'));
      } else if (value !== this.JZAccountPassword.newPassword) {
        callback(new Error('新密码与确认密码不一致！'));
      } else {
        callback();
      }
    },
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
/deep/ .statusContainer {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(36, 40, 50, 0.4);
  li {
    display: inline-block;
    margin-right: 12px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    &.active {
      font-size: 16px;
      font-weight: 500;
      color: #5195eb;
    }
  }
}

/deep/ {
  .busy {
    color: #fc4e4e;
  }

  .free {
    color: rgba(64, 177, 7, 0.8);
  }

  .content-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
